<template>
  <div>
    <template v-if="allows_crud">
      <v-select
        v-if="rubric.coevaluation_type == 1"
        class="mt-2"
        id="select-seleted_evaluatee"
        :placeholder="`Seleccione uno o varios para filtrar.`"
        :options="avaibleUser"
        v-model="coevaluator_id"
        :reduce="(avaibleUser) => avaibleUser.id"
        track-by="id"
        label="full_name"
        size="sm"
        :disabled="!allows_crud"
        :dropdown-should-open="dropdownShouldOpen"
        @input="changeCoevaluatorSingle"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontró resultados para <em>{{ search }}</em
            >.
          </template>
        </template>
        <template v-slot:option="option">
          {{ option.full_name }}
        </template>
      </v-select>
      <v-select
        v-else
        class="mt-2"
        id="select-seleted_evaluatee"
        :placeholder="`Seleccione uno o varios para filtrar.`"
        :options="avaibleUser"
        v-model="evaluatee_rubric.coevaluators"
        :reduce="(avaibleUser) => avaibleUser.id"
        track-by="id"
        label="full_name"
        size="sm"
        :disabled="!allows_crud"
        :dropdown-should-open="dropdownShouldOpen"
        :selectable="
          (option) => !evaluatee_rubric.coevaluators.includes(option.id)
        "
        multiple
        @input="changeCoevaluator"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontró resultados para <em>{{ search }}</em
            >.
          </template>
        </template>
        <template v-slot:option="option">
          {{ option.full_name }}
        </template>
      </v-select>
    </template>
    <div v-else-if="coevaluatorUsers.length > 0" class="text-left">
      {{ coevaluatorUsers.join(", ") }}
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { generateUniqueId, toast } from "@/utils/utils";
import "vue-select/dist/vue-select.css";
export default {
  name: "CoEvaluatorSelectedInput",
  components: {
    vSelect,
  },
  props: {
    rubric: {
      type: Object,
      required: true,
    },
    test: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    evaluatee: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      evaluatee_rubric: {
        id: generateUniqueId(),
        feedback: "",
        automated_score: null,
        final_score: null,
        score_as_percentage: null,
        evaluatee: this.evaluatee,
        coevaluators: [],
        rubric: this.rubric.id,
        attachments: [],
      },
      coevaluator_id: null,
    };
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      evaluatee_rubrics: names.EVALUATEE_RUBRICS,
      new_test_groups: names.NEW_TEST_GROUPS,
    }),
    newTestGroups() {
      return this.new_test_groups.filter((x) => x.test == this.test.id);
    },
    newTestGroupStudent() {
      return this.newTestGroups.find((x) =>
        x.students.includes(this.evaluatee)
      );
    },
    evaluateeRubric() {
      return this.evaluatee_rubrics.find(
        (x) => x.evaluatee == this.evaluatee && x.rubric == this.rubric.id
      );
    },
    filterUser() {
      return this.users
        .filter((x) => this.test.evaluatees.includes(x.id))
        .map((x) => ({
          ...x,
          full_name: x.user_data
            ? x.user_data.identifier + " " + x.first_name + " " + x.last_name
            : x.first_name + " " + x.last_name,
        }));
    },
    filterEvaluateeRubrics() {
      return this.evaluatee_rubrics.filter((x) => x.rubric == this.rubric.id);
    },
    avaibleUser() {
      let coevaluator_ids = [];
      // En caso de que sea 1 será coevaluación simple y se filtrara los usuarios disponibles, en caso contrario permitir a repetirse.
      if (this.rubric.coevaluation_type == 1)
        coevaluator_ids = [
          ...new Set(
            this.filterEvaluateeRubrics
              .filter(
                (x) =>
                  x.coevaluators.length > 0 && x.id != this.evaluatee_rubric.id
              )
              .map((x) => x.coevaluators)
              .flat()
          ),
        ];
      if (this.newTestGroupStudent)
        return this.filterUser.filter(
          (x) =>
            !coevaluator_ids.includes(x.id) &&
            x.id != this.evaluatee &&
            (this.newTestGroupStudent.students.includes(x.id) ||
              this.evaluatee_rubric.coevaluators.includes(x.id))
        );
      else
        return this.filterUser.filter(
          (x) => !coevaluator_ids.includes(x.id) && x.id != this.evaluatee
        );
    },
    coevaluatorUsers() {
      if (isNaN(this.evaluatee_rubric.id)) return [];
      else
        return this.avaibleUser
          .filter((x) => this.evaluatee_rubric.coevaluators.includes(x.id))
          .map((x) => x.full_name);
    },
  },
  methods: {
    dropdownShouldOpen(VueSelect) {
      if (this.avaibleUser.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    changeCoevaluatorSingle(value) {
      if (
        this.allows_crud &&
        this.rubric &&
        this.rubric.coevaluation_type == 1
      ) {
        if (
          value != null &&
          !this.evaluatee_rubric.coevaluators.includes(value)
        ) {
          this.evaluatee_rubric.coevaluators = [value];
          this.saveEvaluateeRubric();
        } else if (value == null) {
          this.evaluatee_rubric.coevaluators = [];
          this.saveEvaluateeRubric();
        }
      }
    },
    changeCoevaluator() {
      if (this.allows_crud && this.rubric && this.rubric.coevaluation_type == 2)
        this.saveEvaluateeRubric();
    },
    saveEvaluateeRubric() {
      if (isNaN(this.evaluatee_rubric.id)) this.createdEvaluateeRubric();
      else this.updatedEvaluateeRubric();
    },
    createdEvaluateeRubric() {
      this.$store
        .dispatch(names.POST_EVALUATEE_RUBRIC, this.evaluatee_rubric)
        .then(() => {
          if (this.evaluatee_rubric.coevaluators.length > 0)
            toast("Evaluado Asignado Satisfactoriamente");
          else toast("Evaluado Eliminado");
        });
    },
    updatedEvaluateeRubric() {
      if (!isNaN(this.evaluatee_rubric.id))
        this.$store
          .dispatch(names.PATCH_EVALUATEE_RUBRIC, {
            evaluatee_rubric_id: this.evaluatee_rubric.id,
            item: {
              coevaluators: this.evaluatee_rubric.coevaluators,
            },
          })
          .then(() => {
            if (this.evaluatee_rubric.coevaluators.length > 0)
              toast("Evaluado Asignado Satisfactoriamente");
            else toast("Evaluado Eliminado");
          });
    },
  },
  watch: {
    evaluateeRubric(evaluateeRubric) {
      if (evaluateeRubric) {
        this.evaluatee_rubric = { ...evaluateeRubric };
        if (evaluateeRubric.coevaluators.length > 0)
          this.coevaluator_id = evaluateeRubric.coevaluators[0];
      }
    },
  },
  created() {
    if (this.evaluateeRubric) {
      this.evaluatee_rubric = { ...this.evaluateeRubric };
      if (this.evaluateeRubric.coevaluators.length > 0)
        this.coevaluator_id = this.evaluateeRubric.coevaluators[0];
    }
  },
};
</script>

<style scoped>
</style>

